<template>
  <div id="app" class="app-container">
    <router-view name="header" />
    <router-view name="body" />
    <router-view name="footer" />
  </div>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "%s | Revealing Clinic ",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width,initial-scale=1.0" },
      { vmid: "img", name: "image", content: "<%= BASE_URL %>logo.png" },
      {
        vmid: "description",
        description:
          "คลินิกศัลยกรรมเสริมความงาม ดูแลผิว เวชศาสตร์ชะลอวัย ปรับรูปหน้า เติมวิตามินผิว ลดไขมัน ลดสิว ฝ้า กระ จุดด่างดำ ทำเลเซอร์หน้า กำจัดขน"
      },
      // ? Schema.org for Google
      {
        itemprop: "name",
        content: "Revealing Clinic"
      },
      {
        vmid: "prop-description",
        itemprop: "description",
        content:
          "คลินิกศัลยกรรมเสริมความงาม ดูแลผิว เวชศาสตร์ชะลอวัย ปรับรูปหน้า เติมวิตามินผิว ลดไขมัน ลดสิว ฝ้า กระ จุดด่างดำ ทำเลเซอร์หน้า กำจัดขน"
      },
      {
        vmid: "prop-img",
        itemprop: "image",
        content: "<%= BASE_URL %>logo.png"
      },
      // ? Twitter
      {
        name: "twitter:card",
        content: "summary"
      },
      {
        name: "twitter:title",
        content: "Revealing Clinic"
      },
      {
        vmid: "twitter-description",
        name: "twitter:description",
        content:
          "คลินิกศัลยกรรมเสริมความงาม ดูแลผิว เวชศาสตร์ชะลอวัย ปรับรูปหน้า เติมวิตามินผิว ลดไขมัน ลดสิว ฝ้า กระ จุดด่างดำ ทำเลเซอร์หน้า กำจัดขน"
      },
      {
        name: "twitter:site",
        content: "https://revealingclinic.com/"
      },
      {
        name: "twitter:create",
        content: "vasin@peach@tachid"
      },
      {
        vmid: "twitter-img",
        name: "twitter:image:src",
        content: "<%= BASE_URL %>logo.png"
      },
      // ? Open Graph general (Facebook, Pinterest & Google+)
      {
        name: "og:title",
        content: "Revealing Clinic"
      },
      {
        vmid: "og-description",
        name: "og:description",
        content:
          "คลินิกศัลยกรรมเสริมความงาม ดูแลผิว เวชศาสตร์ชะลอวัย ปรับรูปหน้า เติมวิตามินผิว ลดไขมัน ลดสิว ฝ้า กระ จุดด่างดำ ทำเลเซอร์หน้า กำจัดขน"
      },
      {
        vmid: "og-img",
        name: "og:image",
        content: "<%= BASE_URL %>logo.png"
      },
      {
        name: "og:url",
        content: window.location.origin
      },
      {
        name: "og:site_name",
        content: "Revealing Clinic"
      },
      {
        name: "og:locale",
        content: "en_us"
      },
      {
        name: "og:type",
        content: "website"
      },
      {
        name: "fb:admins",
        content: "245647242188010"
      },
      {
        name: "fb:app_id",
        content: "1400322780261711"
      }
    ]
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500,700&display=swap");
@import "~bootstrap/scss/bootstrap.scss";
@import "@scss/global.scss";

.app-container {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
}
</style>
