import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
// import Home from "./views/Home.vue";

const Home = () => import("@components/home/Home.vue");
const Contact = () => import("@components/contact/Contact.vue");
const Review = () => import("@components/review/Review.vue");
const Blogs = () => import("@components/blogs/Blogs.vue");

// Service
const Service = () => import("@components/service/Service.vue");
const ServiceSub = () => import("@components/service/ServiceSub.vue");
const ServiceContent = () => import("@components/service/ServiceContent.vue");

const Header = () => import("@components/Header.vue");
const Body = () => import("@components/Body.vue");
const Footer = () => import("@components/Footer.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      components: {
        header: Header,
        body: Body,
        footer: Footer
      },
      children: [
        {
          path: "/home",
          name: "Home",
          alias: "/",
          component: Home
        },
        {
          path: "/service",
          component: Service,
          children: [
            {
              path: ":subname",
              name: "ServiceSub",
              component: ServiceSub,
              props: true,
              children: [
                {
                  path: ":contentname",
                  alias: "",
                  name: "ServiceContent",
                  component: ServiceContent,
                  props: true
                }
              ]
            }
          ]
        },
        {
          path: "/contact",
          name: "Contact",
          component: Contact
        },
        {
          path: "/blogs",
          name: "Blogs",
          component: Blogs
        },
        {
          path: "/review",
          name: "Review",
          component: Review
        }
      ]
    }
  ],
  scrollBehavior(to) {
    return new Promise(resolve => {
      if (to.hash) {
        // scroll to anchor (hash)
        setTimeout(() => {
          var productElement = document.querySelector(`${to.hash}`);
          var productOffset = productElement.offsetTop;
          return resolve(
            window.scrollTo({
              top: productOffset - 60,
              behavior: "smooth"
            })
          );
        }, 400);
      } else if (to.name != "ServiceContent") {
        setTimeout(() => {
          resolve(
            window.scrollTo({
              top: { x: 0, y: 0 },
              behavior: "smooth"
            })
          );
        }, 300);
      }
    });
  }
});
