import Vue from "vue";
import VueMeta from "vue-meta";
import BootstrapVue from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import Viewer from "v-viewer";
import VueLazyload from "vue-lazyload";
import "viewerjs/dist/viewer.css";
import VueYouTubeEmbed from "vue-youtube-embed";

// import store from "./store";

Vue.config.productionTip = process.env.NODE_ENV == "production" ? false : true;

Vue.use(Viewer);
Vue.use(BootstrapVue);
Vue.use(VueYouTubeEmbed);
Vue.use(VueMeta);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "./loading.svg",
  loading: "./loading.svg",
  attempt: 1
});

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount("#app");
